.app-header {
  padding: 35px 20px 0px;
  text-align: center;

  .logo {
    img {
      max-width: 268px;
      width: 100%;
      margin: 0;
      vertical-align: bottom;
    }
  }

  .social-media-container {
    display: none;

    .social-media-icon {
      padding: 8px;
      font-size: 25px;
    }
  }
}

@media all and (min-width: 768px) {
  .app-header {
    margin: 0 auto;
    padding: 60px 70px 25px;
    text-align: left;

    .logo {
      margin-bottom: 26px;

      img {
        max-width: 348px;
        width: 348px;
      }
    }

    .social-media-container {
      display: block;
    }
  }
}

@media all and (min-width: 1024px) {
  .app-header {
    display: flex;
    justify-content: space-between;
    padding: 35px 98px 0;

    .social-media-container {
      margin-right: 18px;
    }
  }
}
