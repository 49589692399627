@import './_normalize.scss';

html,
body {
  /* this height sets the main divs to full page height */
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family:  'Lato', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  font-size: 100%;
  line-height: 1.4;
}

#root {
  /* this height sets the main divs to full page height */
  width: 100%;
  min-height: 100%;
}

h1 {
  margin-top: 0;
  margin-bottom: 16px;
}

h3 {
  margin-top: 0;
  margin-bottom: 8px;

  font-size: 24px;
  text-transform: uppercase;
}

p {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
}

a {
  color: white;
  text-decoration: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}

@media all and (min-width: 768px) {
  h3 {
    font-size: 28px;
  }

  p {
    font-size: 18px;
  }
}
