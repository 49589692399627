.user-section {
  position: relative;
  text-align: center;

  &.client {
    .user-section--image-container {
      margin-bottom: 20px;
    }

    .user-section--image {
      position: relative;
      left: 50%;
      max-width: none;
      height: 383px;
      transform: translate(-50%, 0);
    }

    .user-section--text {
      margin-bottom: 28px;
    }

    .user-section--button {
      margin-bottom: 40px;
    }

    .social-media-container {
      .social-media-icon {
        padding: 8px;
        font-size: 25px;
      }
    }
  }

  &.provider {
    .user-section--image-container {
      display: none;
    }

    .user-section--text {
      margin-bottom: 38px;
    }
  }

  .user-section--content {
    margin-bottom: 40px;
  }

  .button {
    width: 100%;
    max-width: 233px;
    padding: 12px 14px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-transform: uppercase;
    cursor: pointer;
    border: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
    background: #C527BF;
    background-image: linear-gradient(-180deg, #C527BF 0%, #92118B 100%);
  }
}

@media all and (min-width: 768px) {
  .user-section {
    margin: 0 auto;

    &.client {
      display: flex;
      width: calc(100% - 70px);
      margin-bottom: 68px;
      padding: 0 35px;

      .user-section--content {
        width: 100%;
      }

      .user-section--image-container {
        position: absolute;
        bottom: 20%;
        left: 56%;
        width: 50%;
      }

      .user-section--image {
        left: auto;
        height: 375px;
        transform: none;
      }

      .user-section--text {
        margin-bottom: 44px;
        text-align: left;
      }

      .social-media-container {
        display: none;
      }
    }

    &.provider {
      display: flex;
      padding: 0 43px 0 0;

      .user-section--content {
        width: 50%;
        margin: 0;
        padding-left: 3%;
        text-align: left;
        order: 1;
      }

      .user-section--title {
        margin: 2px 0 22px;
        letter-spacing: 2px;
      }

      .user-section--text {
        margin-bottom: 60px;
      }

      .user-section--image-container {
        display: block;
        position: relative;
        min-width: 50%;
        height: 372px;
        overflow: hidden;
      }

      .user-section--image {
        position: absolute;
        right: 27px;
        max-width: 414px;
        max-height: none;
      }
    }

    .button {
      max-width: 314px;
      margin: 0 auto;
      padding: 23px 14px;
      font-size: 17px;
    }
  }
}

@media all and (min-width: 880px) {
  .user-section {
    &.provider {
      .user-section--image {
        right: auto;
        left: 0;
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .user-section {
    padding: 0 98px;
    text-align: left;

    &.client {
      padding: 0 60px;

      .user-section--text {
        margin-bottom: 22px;
      }

      .user-section--image-container {
        bottom: -38%;
        left: 48%;
      }

      .user-section--image {
        min-height: 430px;
      }
    }

    &.provider {
      .user-section--content {
        padding-left: 2%;
      }

      .user-section--title {
        margin-top: 5px;
      }

      .user-section--text {
        margin-bottom: 24px;
      }

      .user-section--image-container {
        height: 402px;
      }

      .user-section--image {
        max-width: 465px;
        left: -55px;
      }
    }
  }
}
